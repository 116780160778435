define('ember-google-charts/utils/render-chart', ['exports', 'ember-google-charts/utils/get-visualization-name', 'ember-google-charts/utils/format-data'], function (exports, _getVisualizationName, _formatData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = renderChart;
  function renderChart(element, { data, type, design = 'classic', options = {} }) {
    return new Ember.RSVP.Promise((resolve, reject) => {
      const { google: { charts, visualization } } = window;

      const capitalizedType = Ember.String.capitalize(type);
      const isAsyncChart = type === 'geo';
      const isMaterialChart = design === 'material';
      const constructorName = isMaterialChart ? capitalizedType : (0, _getVisualizationName.default)(type);

      const googlePackage = isMaterialChart ? charts : visualization;
      const chartConstructor = googlePackage[constructorName];

      function formatOptions(options) {
        return isMaterialChart ? charts[capitalizedType].convertOptions(options) : options;
      }

      /* Create the chart */

      const chart = new chartConstructor(element);

      /* For charts that are are created asyncronously, listen for the
      ready event */

      if (isAsyncChart) {
        visualization.events.addListener(chart, 'ready', function () {
          resolve(chart);
        });
      }

      /* Make sure errors in render are caught */

      visualization.events.addListener(chart, 'error', reject);

      /* Make the Google Chart object publically available (e.g. for use in tests) */

      element.chart = chart;

      /* Render the chart */

      chart.draw((0, _formatData.default)(data), formatOptions(options));

      if (!isAsyncChart) {
        resolve(chart);
      }
    });
  }
});