define('ember-google-charts/components/area-chart', ['exports', 'ember-google-charts/components/google-chart'], function (exports, _googleChart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _googleChart.default.extend({
    design: 'classic',
    type: 'area'
  });
});