define("ember-math-helpers/helpers/imul", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.imul = imul;

  /**
   * Executes `Math.imul` on the number passed to the helper.
   *
   * ```hbs
   * {{imul a}}
   * ```
   *
   * @param {number} number1 The first number to pass to `Math.imul`
   * @param {number} number2 The second number to pass to `Math.imul`
   * @return {number} The imul of the passed numbers
   */
  function imul([number1, number2]) {
    return Math.imul(number1, number2);
  }

  var _default = Ember.Helper.helper(imul);

  _exports.default = _default;
});