define("ember-paper/mixins/parent-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ParentMixin
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    childComponents: Ember.computed(function () {
      return Ember.A();
    }),

    register(child) {
      this.get('childComponents').pushObject(child);
    },

    unregister(child) {
      this.get('childComponents').removeObject(child);
    }

  });

  _exports.default = _default;
});