define("ember-simple-auth-auth0/authenticators/auth0-url-hash", ["exports", "ember-simple-auth-auth0/authenticators/auth0-base"], function (_exports, _auth0Base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _auth0Base.default.extend({
    auth0: Ember.inject.service(),
    session: Ember.inject.service(),

    authenticate(urlHashData) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this._resolveAuthResult(urlHashData, resolve, reject);
      });
    }

  });

  _exports.default = _default;
});