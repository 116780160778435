define("ember-paper/components/paper-divider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v6pc+yWA",
    "block": "{\"symbols\":[\"@inset\",\"&attrs\"],\"statements\":[[11,\"md-divider\"],[24,0,\"md-default-theme\"],[16,\"md-inset\",[32,1]],[17,2],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-paper/components/paper-divider/template.hbs"
    }
  });

  _exports.default = _default;
});