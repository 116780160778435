define('ember-google-charts/services/google-charts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    language: 'en',

    init() {
      this._super(...arguments);

      this.googlePackages = this.googlePackages || ['corechart', 'bar', 'line', 'scatter'];
      this.defaultOptions = this.defaultOptions || {
        animation: {
          duration: 500,
          startup: false
        }
      };
    },

    loadPackages() {
      const { google: { charts } } = window;

      return new Ember.RSVP.Promise((resolve, reject) => {
        const packagesAreLoaded = charts.loader;

        if (packagesAreLoaded) {
          resolve();
        } else {
          charts.load('current', {
            language: this.get('language'),
            packages: this.get('googlePackages')
          });

          charts.setOnLoadCallback(ex => {
            if (ex) {
              reject(ex);
            }

            resolve();
          });
        }
      });
    }
  });
});