define("ember-paper/mixins/child-mixin", ["exports", "ember-paper/mixins/parent-mixin"], function (_exports, _parentMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ChildMixin
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    // override to look for a specific parent class
    parentClass: _parentMixin.default,
    // this will typically be overriden when yielding a contextual component
    parentComponent: Ember.computed({
      get() {
        if (this._parentComponent !== undefined) {
          return this._parentComponent;
        }

        return this.nearestOfType(this.get('parentClass'));
      },

      set(key, value) {
        return this._parentComponent = value;
      }

    }),

    init() {
      this._super(...arguments);

      if (this.get('parentComponent')) {
        this.get('parentComponent').register(this);
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.get('parentComponent')) {
        this.get('parentComponent').unregister(this);
      }
    }

  });

  _exports.default = _default;
});