define("ember-table/components/ember-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lej60MOJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"table\"],[12],[2,\"\\n  \"],[18,1,[[30,[36,2],null,[[\"api\",\"head\",\"body\",\"foot\"],[[35,0],[30,[36,1],[\"ember-thead\"],[[\"api\"],[[35,0]]]],[30,[36,1],[\"ember-tbody\"],[[\"api\"],[[35,0]]]],[30,[36,1],[\"ember-tfoot\"],[[\"api\"],[[35,0]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"api\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-table/components/ember-table/template.hbs"
    }
  });

  _exports.default = _default;
});