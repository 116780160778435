define("ember-table/-private/column-tree", ["exports", "ember-table/-private/utils/observer", "ember-raf-scheduler", "ember-table/-private/meta-cache", "ember-table/-private/utils/array", "ember-table/-private/utils/sort", "ember-table/-private/utils/element", "ember-table/-private/utils/reorder-indicators", "ember-table/-private/utils/ember"], function (_exports, _observer, _emberRafScheduler, _metaCache, _array, _sort, _element, _reorderIndicators, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.WIDTH_CONSTRAINT = _exports.RESIZE_MODE = _exports.FILL_MODE = void 0;
  const SCROLL_THRESHOLD = 50;
  const DEFAULT_COLUMN_WIDTH = 100;
  const DEFAULT_MIN_WIDTH = 50;
  const DEFAULT_MAX_WIDTH = Infinity;
  const LOOP_COUNT_GUARD = 500;
  const RESIZE_MODE = {
    STANDARD: 'standard',
    FLUID: 'fluid'
  };
  _exports.RESIZE_MODE = RESIZE_MODE;
  const FILL_MODE = {
    EQUAL_COLUMN: 'equal-column',
    FIRST_COLUMN: 'first-column',
    LAST_COLUMN: 'last-column',
    NTH_COLUMN: 'nth-column'
  };
  _exports.FILL_MODE = FILL_MODE;
  const WIDTH_CONSTRAINT = {
    NONE: 'none',
    EQ_CONTAINER: 'eq-container',
    GTE_CONTAINER: 'gte-container',
    LTE_CONTAINER: 'lte-container'
  };
  /**
   * Divides x into y pieces where all y pieces are rounded
   * and sum to x. Assumes x is already rounded.
   * Returns a list of the pieces.
   *
   * For example:
   * 10 / 3 => [4, 3, 3]
   * -11 / 2 => [-6, -5]
   */

  _exports.WIDTH_CONSTRAINT = WIDTH_CONSTRAINT;

  function divideRounded(x, n) {
    let neg = x < 0 === n < 0 ? 1 : -1;
    n = Math.abs(n);
    x = Math.abs(x);
    let z = Math.floor(x / n);
    let err = x - n * z;
    let result = Array(n);
    result.fill(neg * (z + 1), 0, err);
    result.fill(neg * z, err);
    return result;
  }

  const TableColumnMeta = Ember.Object.extend({
    // If no width is set on the column itself, we cache a temporary width on the
    // meta object. This is set to the default width.
    _width: DEFAULT_COLUMN_WIDTH,
    isLeaf: Ember.computed.readOnly('_node.isLeaf'),
    isFixed: Ember.computed.readOnly('_node.isFixed'),
    isSortable: Ember.computed.readOnly('_node.isSortable'),
    isResizable: Ember.computed.readOnly('_node.isResizable'),
    isReorderable: Ember.computed.readOnly('_node.isReorderable'),
    width: Ember.computed.readOnly('_node.width'),
    offsetLeft: Ember.computed.readOnly('_node.offsetLeft'),
    offsetRight: Ember.computed.readOnly('_node.offsetRight'),
    rowSpan: Ember.computed('isLeaf', '_node.{depth,tree.root.maxChildDepth}', function () {
      if (!this.get('isLeaf')) {
        return 1;
      }

      let maxDepth = this.get('_node.tree.root.maxChildDepth');
      let depth = this.get('_node.depth');
      return maxDepth - (depth - 1);
    }),
    columnSpan: Ember.computed('isLeaf', '_node.leaves.length', function () {
      if (this.get('isLeaf')) {
        return 1;
      }

      return this.get('_node.leaves.length');
    }),
    index: Ember.computed('isLeaf', '_node.offsetIndex', function () {
      if (this.get('isLeaf')) {
        return this.get('_node.offsetIndex');
      }
    }),
    sortIndex: Ember.computed('_node.{tree.sorts.[],column.valuePath}', function () {
      let valuePath = this.get('_node.column.valuePath');
      let sorts = this.get('_node.tree.sorts');
      let sortIndex = 0;

      for (let i = 0; i < Ember.get(sorts, 'length'); i++) {
        let sorting = (0, _array.objectAt)(sorts, i);

        if (Ember.get(sorting, 'valuePath') === valuePath) {
          sortIndex = i + 1;
          break;
        }
      }

      return sortIndex;
    }),
    isSorted: Ember.computed.gt('sortIndex', 0),
    isMultiSorted: Ember.computed.gt('_node.tree.sorts.length', 1),
    isSortedAsc: Ember.computed('_node.tree.sorts.[]', 'sortIndex', function () {
      let sortIndex = this.get('sortIndex');
      let sorts = this.get('_node.tree.sorts');
      return Ember.get((0, _array.objectAt)(sorts, sortIndex - 1), 'isAscending');
    })
  });
  /**
    Single node of a ColumnTree
  */

  const ColumnTreeNode = Ember.Object.extend({
    _subcolumnNodes: null,

    init() {
      this._super(...arguments);

      let tree = Ember.get(this, 'tree');
      let parent = Ember.get(this, 'parent');
      let column = Ember.get(this, 'column');

      if (!parent) {
        this.isRoot = true;
      } else {
        let meta = (0, _metaCache.getOrCreate)(column, Ember.get(tree, 'columnMetaCache'), TableColumnMeta);
        Ember.set(meta, '_node', this);

        meta.registerElement = (...args) => this.registerElement(...args);

        meta.startResize = (...args) => tree.startResize(this, ...args);

        meta.updateResize = (...args) => tree.updateResize(this, ...args);

        meta.endResize = (...args) => tree.endResize(this, ...args);

        meta.startReorder = (...args) => tree.startReorder(this, ...args);

        meta.updateReorder = (...args) => tree.updateReorder(this, ...args);

        meta.endReorder = (...args) => tree.endReorder(this, ...args); // Changes to the value directly should properly update all computeds on this
        // node, but we need to manually propagate changes upwards to notify any other
        // watchers


        this._notifyMaxChildDepth = () => (0, _ember.notifyPropertyChange)(parent, 'maxChildDepth');

        this._notifyLeaves = () => (0, _ember.notifyPropertyChange)(parent, 'leaves');

        (0, _observer.addObserver)(this, 'maxChildDepth', this._notifyMaxChildDepth);
        (0, _observer.addObserver)(this, 'leaves.[]', this._notifyLeaves);
      }
    },

    destroy() {
      this.cleanSubcolumnNodes();

      this._super(...arguments);
    },

    /**
      Fully destroys the child nodes in the event that they change or that this
      node is destroyed. If children are not destroyed, they will leak memory due
      to dangling references in Ember Meta.
    */
    cleanSubcolumnNodes() {
      if (this._subcolumnNodes !== null) {
        this._subcolumnNodes.forEach(n => n.destroy());

        this._subcolumnNodes = null;
      }
    },

    subcolumnNodes: Ember.computed('column.subcolumns.[]', function () {
      this.cleanSubcolumnNodes();

      if (Ember.get(this, 'isLeaf')) {
        return;
      }

      let tree = Ember.get(this, 'tree');
      let parent = this;
      this._subcolumnNodes = Ember.A(Ember.get(this, 'column.subcolumns').map(column => ColumnTreeNode.create({
        column,
        tree,
        parent
      })));
      return this._subcolumnNodes;
    }),
    isLeaf: Ember.computed('column.subcolumns.[]', 'isRoot', function () {
      let subcolumns = Ember.get(this, 'column.subcolumns');

      if (Ember.get(this, 'isRoot')) {
        return false;
      }

      return !subcolumns || Ember.get(subcolumns, 'length') === 0;
    }),
    isSortable: Ember.computed('column.isSortable', 'tree.enableSort', function () {
      let enableSort = Ember.get(this, 'tree.enableSort');
      let valuePath = Ember.get(this, 'column.valuePath');
      let isSortable = Ember.get(this, 'column.isSortable');
      let isLeaf = Ember.get(this, 'isLeaf');
      return isLeaf === true && enableSort !== false && isSortable !== false && typeof valuePath === 'string';
    }),
    isReorderable: Ember.computed('column.isReorderable', 'tree.enableReorder', function () {
      let enableReorder = Ember.get(this, 'tree.enableReorder');
      let isReorderable = Ember.get(this, 'column.isReorderable');
      return enableReorder !== false && isReorderable !== false;
    }),
    isResizable: Ember.computed('column.isResizable', 'tree.enableResize', function () {
      let isLeaf = Ember.get(this, 'isLeaf');

      if (isLeaf) {
        let enableResize = Ember.get(this, 'tree.enableResize');
        let isResizable = Ember.get(this, 'column.isResizable');
        return enableResize !== false && isResizable !== false;
      } else {
        let subcolumns = Ember.get(this, 'subcolumnNodes');
        return subcolumns.some(s => Ember.get(s, 'isResizable'));
      }
    }),
    isFixed: Ember.computed('parent.{isFixed,isRoot}', 'column.isFixed', function () {
      if (Ember.get(this, 'parent.isRoot')) {
        return Ember.get(this, 'column.isFixed');
      }

      return Ember.get(this, 'parent.isFixed');
    }),
    depth: Ember.computed('parent.depth', function () {
      if (Ember.get(this, 'parent')) {
        return Ember.get(this, 'parent.depth') + 1;
      }

      return 0;
    }),
    maxChildDepth: Ember.computed('isLeaf', 'subcolumns.@each.depth', function () {
      if (Ember.get(this, 'isLeaf')) {
        return Ember.get(this, 'depth');
      }

      return Math.max(...Ember.get(this, 'subcolumnNodes').map(s => Ember.get(s, 'maxChildDepth')));
    }),
    leaves: Ember.computed('isLeaf', 'subcolumnNodes.{[],@each.leaves}', function () {
      if (Ember.get(this, 'isLeaf')) {
        return [this];
      }

      return Ember.get(this, 'subcolumnNodes').reduce((leaves, subcolumn) => {
        leaves.push(...Ember.get(subcolumn, 'leaves'));
        return leaves;
      }, Ember.A());
    }),
    minWidth: Ember.computed('column.minWidth', function () {
      if (Ember.get(this, 'isLeaf')) {
        let columnMinWidth = Ember.get(this, 'column.minWidth');
        return typeof columnMinWidth === 'number' ? columnMinWidth : DEFAULT_MIN_WIDTH;
      }

      return Ember.get(this, 'subcolumnNodes').reduce((sum, subcolumn) => {
        let subcolumnMinWidth = Ember.get(subcolumn, 'minWidth');
        return sum + subcolumnMinWidth;
      }, 0);
    }),
    maxWidth: Ember.computed('column.minWidth', function () {
      if (Ember.get(this, 'isLeaf')) {
        let columnMaxWidth = Ember.get(this, 'column.maxWidth');
        return typeof columnMaxWidth === 'number' ? columnMaxWidth : DEFAULT_MAX_WIDTH;
      }

      return Ember.get(this, 'subcolumnNodes').reduce((sum, subcolumn) => {
        let subcolumnMaxWidth = Ember.get(subcolumn, 'maxWidth');
        return sum + subcolumnMaxWidth;
      }, 0);
    }),
    width: Ember.computed('isLeaf', 'subcolumnNodes.@each.width', 'column.width', {
      get() {
        if (Ember.get(this, 'isLeaf')) {
          let column = Ember.get(this, 'column');
          let columnWidth = Ember.get(column, 'width');

          if (typeof columnWidth === 'number') {
            return columnWidth;
          } else {
            let meta = Ember.get(this, 'tree.columnMetaCache').get(column);
            return Ember.get(meta, '_width');
          }
        }

        return Ember.get(this, 'subcolumnNodes').reduce((sum, subcolumn) => {
          let subcolumnWidth = Ember.get(subcolumn, 'width');
          return sum + subcolumnWidth;
        }, 0);
      },

      set(key, newWidth) {
        let oldWidth = Ember.get(this, 'width');
        let isResizable = Ember.get(this, 'isResizable');

        if (!isResizable) {
          return oldWidth;
        }

        let delta = newWidth - oldWidth;
        let minWidth = Ember.get(this, 'minWidth');
        let maxWidth = Ember.get(this, 'maxWidth');
        delta = Math.max(Math.min(oldWidth + delta, maxWidth), minWidth) - oldWidth;

        if (delta === 0) {
          return oldWidth;
        }

        if (Ember.get(this, 'isLeaf')) {
          let column = Ember.get(this, 'column');
          let columnWidth = Ember.get(column, 'width');
          let width = oldWidth + delta;

          if (typeof columnWidth === 'number') {
            Ember.set(column, 'width', width);
          } else {
            let meta = Ember.get(this, 'tree.columnMetaCache').get(column);
            Ember.set(meta, '_width', width);
          }

          return width;
        } else {
          let subcolumns = Ember.get(this, 'subcolumnNodes'); // Delta can only be rendered at a pixel level of precision in tables in
          // some browsers, so we round and distribute the remainder as well. We also
          // don't know when we may hit a constraint (e.g. minWidth) so we have to do
          // this repeatedly. We take the largest chunk we can and try to fit it into
          // each piece in a loop.
          // We distribute chunks to the columns starting from the column with the
          // smallest width to the column with the largest width.

          let sortedSubcolumns = subcolumns.sortBy('width').filter(n => Ember.get(n, 'isResizable')).reverse();
          let loopCount = 0;
          let prevDelta = 0;
          delta = delta > 0 ? Math.floor(delta) : Math.ceil(delta);

          while (delta !== 0) {
            let deltaChunks = divideRounded(delta, sortedSubcolumns.length);

            for (let i = 0; i < deltaChunks.length; i++) {
              let subcolumn = sortedSubcolumns[i];
              let deltaChunk = deltaChunks[i];
              let oldWidth = Ember.get(subcolumn, 'width');
              let targetWidth = oldWidth + deltaChunk;
              Ember.set(subcolumn, 'width', targetWidth);
              let newWidth = Ember.get(subcolumn, 'width'); // subtract the amount that changed, if any

              delta -= newWidth - oldWidth;

              if (delta === 0) {
                break;
              }
            }

            delta = delta > 0 ? Math.floor(delta) : Math.ceil(delta); // If we weren't able to change the delta at all, then we hit a hard
            // barrier. This can happen when a table has too many columns to size
            // down, for instance.

            if (prevDelta === delta) {
              break;
            }

            prevDelta = delta;
            loopCount++;

            if (loopCount > LOOP_COUNT_GUARD) {
              throw new Error('loop count exceeded guard while distributing width');
            }
          }

          return Ember.get(this, 'width');
        }
      }

    }),
    offsetIndex: Ember.computed('parent.{offsetIndex,subcolumnNodes.[]}', function () {
      let parent = Ember.get(this, 'parent');

      if (!parent) {
        return 0;
      }

      let subcolumns = Ember.get(parent, 'subcolumnNodes');
      let offsetIndex = Ember.get(parent, 'offsetIndex');

      for (let column of subcolumns) {
        if (column === this) {
          break;
        }

        offsetIndex += 1;
      }

      return offsetIndex;
    }),
    offsetLeft: Ember.computed('parent.{offsetLeft,width}', function () {
      let parent = Ember.get(this, 'parent');

      if (!parent) {
        return 0;
      }

      let subcolumns = Ember.get(parent, 'subcolumnNodes');
      let offsetLeft = Ember.get(parent, 'offsetLeft');

      for (let column of subcolumns) {
        if (column === this) {
          break;
        }

        offsetLeft += Ember.get(column, 'width');
      }

      return offsetLeft;
    }),
    offsetRight: Ember.computed('parent.{offsetRight,width}', function () {
      let parent = Ember.get(this, 'parent');

      if (!parent) {
        return 0;
      }

      let subcolumns = Ember.get(parent, 'subcolumnNodes').slice().reverse();
      let offsetRight = Ember.get(parent, 'offsetRight');

      for (let column of subcolumns) {
        if (column === this) {
          break;
        }

        offsetRight += Ember.get(column, 'width');
      }

      return offsetRight;
    }),

    registerElement(element) {
      this.element = element;
    }

  });

  var _default = Ember.Object.extend({
    init() {
      this._super(...arguments);

      this.token = new _emberRafScheduler.Token();
      this._sortColumnsByFixed = this.sortColumnsByFixed.bind(this);
      this._ensureWidthConstraint = this.ensureWidthConstraint.bind(this);
      (0, _observer.addObserver)(this, 'columns.@each.isFixed', this._sortColumnsByFixed);
      (0, _observer.addObserver)(this, 'widthConstraint', this._ensureWidthConstraint);
    },

    destroy() {
      this.token.cancel();
      Ember.get(this, 'root').destroy();
      (0, _observer.removeObserver)(this, 'columns.@each.isFixed', this._sortColumnsByFixed);
      (0, _observer.removeObserver)(this, 'widthConstraint', this._ensureWidthConstraint);

      this._super(...arguments);
    },

    root: Ember.computed('columns', function () {
      let columns = Ember.get(this, 'columns');
      return ColumnTreeNode.create({
        column: {
          subcolumns: columns
        },
        tree: this
      });
    }),
    rows: Ember.computed('root.{maxChildDepth,leaves.[]}', function () {
      let rows = Ember.A();
      let root = Ember.get(this, 'root');
      let maxDepth = Ember.get(root, 'maxChildDepth');
      let previousLevel = [root];

      for (let i = 0; i < maxDepth; i++) {
        let currentLevel = previousLevel.reduce((children, node) => {
          if (!Ember.get(node, 'isLeaf')) {
            children.push(...Ember.get(node, 'subcolumnNodes'));
          }

          return children;
        }, []);
        let columns = currentLevel.map(node => Ember.get(node, 'column'));
        rows.pushObject(Ember.A(columns));
        previousLevel = currentLevel;
      }

      return rows;
    }),
    leaves: Ember.computed('root.leaves.[]', function () {
      return Ember.A(Ember.get(this, 'root.leaves').map(n => n.column));
    }),
    leftFixedNodes: Ember.computed('root.subcolumnNodes.@each.isFixed', function () {
      return Ember.get(this, 'root.subcolumnNodes').filterBy('isFixed', 'left');
    }),
    rightFixedNodes: Ember.computed('root.subcolumnNodes.@each.isFixed', function () {
      return Ember.get(this, 'root.subcolumnNodes').filterBy('isFixed', 'right');
    }),
    unfixedNodes: Ember.computed('root.subcolumnNodes.@each.isFixed', function () {
      return Ember.get(this, 'root.subcolumnNodes').filter(s => !Ember.get(s, 'isFixed'));
    }),
    scrollBounds: Ember.computed('leftFixedNodes.@each.width', 'rightFixedNodes.@each.width', function () {
      let {
        left: containerLeft,
        right: containerRight
      } = (0, _element.getInnerClientRect)(this.container);
      containerLeft += Ember.get(this, 'leftFixedNodes').reduce((sum, n) => sum + Ember.get(n, 'width'), 0);
      containerRight -= Ember.get(this, 'rightFixedNodes').reduce((sum, n) => sum + Ember.get(n, 'width'), 0);
      return {
        containerLeft,
        containerRight
      };
    }),

    sortColumnsByFixed() {
      // disable observer
      if (this._isSorting) {
        return;
      }

      this._isSorting = true;
      let columns = Ember.get(this, 'columns');
      let sorted = (0, _sort.mergeSort)(columns, (a, b) => {
        let aIsFixed = Ember.get(a, 'isFixed');
        let bIsFixed = Ember.get(b, 'isFixed');
        let aValue = aIsFixed === 'left' ? -1 : aIsFixed === 'right' ? 1 : 0;
        let bValue = bIsFixed === 'left' ? -1 : bIsFixed === 'right' ? 1 : 0;
        return aValue - bValue;
      });
      let alreadySorted = true;

      for (let i = 0; i < columns.length; i++) {
        if (sorted[i] !== columns[i]) {
          alreadySorted = false;
          break;
        }
      }

      if (!alreadySorted) {
        (0, _array.splice)(columns, 0, sorted.length, ...sorted);
      }

      this._isSorting = false;
    },

    ensureWidthConstraint() {
      if (!this.container) {
        return;
      }

      let containerWidthAdjustment = Ember.get(this, 'containerWidthAdjustment') || 0;
      let containerWidth = (0, _element.getInnerClientRect)(this.container).width * this.scale + containerWidthAdjustment;
      let treeWidth = Ember.get(this, 'root.width');
      let columns = Ember.get(this, 'root.subcolumnNodes');
      let widthConstraint = Ember.get(this, 'widthConstraint');
      let fillMode = Ember.get(this, 'fillMode');
      let fillColumnIndex = Ember.get(this, 'fillColumnIndex');

      if (widthConstraint === WIDTH_CONSTRAINT.EQ_CONTAINER && treeWidth !== containerWidth || widthConstraint === WIDTH_CONSTRAINT.LTE_CONTAINER && treeWidth > containerWidth || widthConstraint === WIDTH_CONSTRAINT.GTE_CONTAINER && treeWidth < containerWidth) {
        let delta = containerWidth - treeWidth;

        if (fillMode === FILL_MODE.EQUAL_COLUMN) {
          Ember.set(this, 'root.width', containerWidth);
        } else if (fillMode === FILL_MODE.FIRST_COLUMN) {
          this.resizeColumn(0, delta);
        } else if (fillMode === FILL_MODE.LAST_COLUMN) {
          this.resizeColumn(columns.length - 1, delta);
        } else if (fillMode === FILL_MODE.NTH_COLUMN) {
          (false && !(!Ember.isEmpty(fillColumnIndex)) && Ember.assert("fillMode 'nth-column' must have a fillColumnIndex defined", !Ember.isEmpty(fillColumnIndex)));
          this.resizeColumn(fillColumnIndex, delta);
        }
      }
    },

    resizeColumn(index, delta) {
      let columns = Ember.get(this, 'root.subcolumnNodes');
      let fillColumn = columns[index];
      (false && !(fillColumn) && Ember.assert(`Invalid column index, ${index}, for a table with ${columns.length} columns`, fillColumn));
      let oldWidth = Ember.get(fillColumn, 'width');
      Ember.set(fillColumn, 'width', oldWidth + delta);
    },

    getReorderBounds(node) {
      let parent = Ember.get(node, 'parent');
      let {
        scale
      } = this;
      let {
        scrollLeft
      } = this.container;
      let {
        left: containerLeft
      } = (0, _element.getInnerClientRect)(this.container);
      let leftBound, rightBound, nodes;

      if (Ember.get(parent, 'isRoot')) {
        let isFixed = Ember.get(node, 'isFixed');

        if (isFixed === 'left') {
          nodes = Ember.get(this, 'leftFixedNodes');
        } else if (isFixed === 'right') {
          nodes = Ember.get(this, 'rightFixedNodes');
        } else {
          nodes = Ember.get(this, 'unfixedNodes');
        }
      } else {
        nodes = Ember.get(node, 'parent.subcolumnNodes');
      }

      if (false
      /* DEBUG */
      ) {
        let firstReorderableFound = false;
        let lastReorderableFound = false;

        for (let node of nodes) {
          if (lastReorderableFound && Ember.get(node, 'isReorderable')) {
            (false && !(false) && Ember.assert('Non-reorderable columns may only be contiguous segments at the beginning or end of their section (i.e. node middle columns in a list).', false));
          } else if (!firstReorderableFound && Ember.get(node, 'isReorderable')) {
            firstReorderableFound = true;
          } else if (firstReorderableFound && !lastReorderableFound && !Ember.get(node, 'isReorderable')) {
            lastReorderableFound = true;
          }
        }
      }

      let reorderableNodes = nodes.filter(n => Ember.get(n, 'isReorderable'));
      let left = (0, _element.getOuterClientRect)(reorderableNodes[0].element).left;
      let right = (0, _element.getOuterClientRect)(reorderableNodes[reorderableNodes.length - 1].element).right;
      leftBound = (left - containerLeft) * scale + scrollLeft;
      rightBound = (right - containerLeft) * scale + scrollLeft;
      return {
        leftBound,
        rightBound
      };
    },

    registerContainer(container) {
      this.container = container;
      this.scale = (0, _element.getScale)(container);
      Ember.get(this, 'root').registerElement(container);

      _emberRafScheduler.scheduler.schedule('sync', this.ensureWidthConstraint.bind(this), this.token);
    },

    getClosestColumn(column, left, isFixed) {
      // If the column is fixed, adjust finder method and offset by the scroll
      // position since the column will appear stationary
      if (isFixed === 'left') {
        left -= this.container.scrollLeft;
      } else if (isFixed === 'right') {
        left += this.container.scrollWidth;
        left -= this.container.scrollLeft;
        left -= (0, _element.getInnerClientRect)(this.container).width * this.scale;
      }

      let subcolumns = Ember.get(column.parent, 'subcolumnNodes');

      for (let column of subcolumns) {
        let offset = Ember.get(column, 'offsetLeft');

        if (left < offset + Ember.get(column, 'width')) {
          return column;
        }
      }

      return subcolumns[subcolumns.length - 1];
    },

    getClosestColumnOffset(column, left, isFixed) {
      let closestColumn = this.getClosestColumn(column, left, isFixed);
      let offsetLeft = Ember.get(closestColumn, 'offsetLeft'); // If the column is fixed, readjust the offset so that it's correct within
      // the container

      if (isFixed === 'left') {
        offsetLeft += this.container.scrollLeft;
      } else if (isFixed === 'right') {
        offsetLeft -= this.container.scrollWidth;
        offsetLeft += this.container.scrollLeft;
        offsetLeft += (0, _element.getInnerClientRect)(this.container).width * this.scale;
      }

      return offsetLeft;
    },

    insertAfterColumn(parent, after, insert) {
      if (insert === after) {
        return;
      }

      let subcolumns = Ember.get(parent, 'column.subcolumns');
      let afterColumn = Ember.get(after, 'column');
      let insertColumn = Ember.get(insert, 'column');
      let afterIndex = subcolumns.indexOf(afterColumn);
      let insertIndex = subcolumns.indexOf(insertColumn);
      (0, _array.move)(subcolumns, insertIndex, afterIndex);
      (0, _ember.notifyPropertyChange)(subcolumns, '[]');
    },

    startReorder(node, clientX) {
      this.clientX = clientX;
      let bounds = this.getReorderBounds(node);
      this._reorderMainIndicator = new _reorderIndicators.MainIndicator(this.container, node.element, bounds);
      this._reorderDropIndicator = new _reorderIndicators.DropIndicator(this.container, node.element, bounds);
      this.container.classList.add('is-reordering');
    },

    updateReorder(node, clientX) {
      this.clientX = clientX;

      this._updateReorder(node);

      if (!Ember.get(node, 'isFixed')) {
        this.updateScroll(node, true, true, this._updateReorder.bind(this));
      }
    },

    _updateReorder(node) {
      let {
        scrollLeft
      } = this.container;
      let realContainerLeft = (0, _element.getInnerClientRect)(this.container).left * this.scale;
      let offset = this.clientX * this.scale - realContainerLeft + scrollLeft;
      let width = Ember.get(node, 'width');
      let newLeft = offset - width / 2;
      this._reorderMainIndicator.left = newLeft;
      this._reorderDropIndicator.left = this.getClosestColumnOffset(node, offset, Ember.get(node, 'isFixed'));
      this._reorderDropIndicator.width = Ember.get(this.getClosestColumn(node, this._reorderDropIndicator.left, Ember.get(node, 'isFixed')), 'width');
    },

    endReorder(node) {
      let {
        scrollLeft
      } = this.container;
      let realContainerLeft = (0, _element.getInnerClientRect)(this.container).left * this.scale;
      let offset = this.clientX * this.scale - realContainerLeft + scrollLeft;
      let {
        leftBound,
        rightBound
      } = this.getReorderBounds(node);
      offset = Math.max(leftBound, offset);
      offset = Math.min(rightBound - 1, offset);
      let closestColumn = this.getClosestColumn(node, offset, Ember.get(node, 'isFixed'));
      this.insertAfterColumn(node.parent, closestColumn, node);

      this._reorderMainIndicator.destroy();

      this._reorderDropIndicator.destroy();

      this._reorderMainIndicator = null;
      this._reorderDropIndicator = null;

      if (this._nextUpdateScroll) {
        this._nextUpdateScroll.cancel();

        this._nextUpdateScroll = null;
      }

      this.container.classList.remove('is-reordering');
      this.sendAction('onReorder', Ember.get(node, 'column'), Ember.get(closestColumn, 'column'));
    },

    startResize(node, clientX) {
      this.clientX = clientX;
    },

    updateResize(node, clientX) {
      let delta = Math.floor(Ember.get(node, 'isFixed') === 'right' ? (this.clientX - clientX) * this.scale : (clientX - this.clientX) * this.scale);
      this.clientX = clientX;

      if (Math.abs(delta) < 1) {
        return;
      } // Add the class after at least one update has occured


      this.container.classList.add('is-resizing');

      this._updateResize(node, delta);
    },

    _updateResize(node, delta) {
      let resizeMode = Ember.get(this, 'resizeMode');
      let oldWidth = Ember.get(node, 'width');
      let minWidth = Ember.get(node, 'minWidth');
      delta = Math.max(oldWidth + delta, minWidth) - oldWidth;

      if (resizeMode === RESIZE_MODE.FLUID) {
        let parent = Ember.get(node, 'parent');
        let child = node;
        let sibling;

        while (parent && !sibling) {
          let siblings = Ember.get(parent, 'subcolumnNodes');
          sibling = siblings[siblings.indexOf(child) + 1];
          child = parent;
          parent = Ember.get(child, 'parent');
        }

        if (sibling) {
          let siblingOldWidth = Ember.get(sibling, 'width');
          let siblingMinWidth = Ember.get(sibling, 'minWidth');
          delta = -(Math.max(siblingOldWidth - delta, siblingMinWidth) - siblingOldWidth);
          Ember.set(sibling, 'width', siblingOldWidth - delta);
        } else {
          delta = 0;
        }
      }

      let newWidth = oldWidth + delta;
      Ember.set(node, 'width', newWidth);
      this.ensureWidthConstraint.call(this);
    },

    endResize(node) {
      if (this._nextUpdateScroll) {
        this._nextUpdateScroll.cancel();

        this._nextUpdateScroll = null;
      }

      this.container.classList.remove('is-resizing');
      this.sendAction('onResize', Ember.get(node, 'column'));
    },

    updateScroll(node, stopAtLeft, stopAtRight, callback) {
      if (this._nextUpdateScroll) {
        return;
      }

      this._nextUpdateScroll = _emberRafScheduler.scheduler.schedule('sync', () => {
        this._nextUpdateScroll = null;
        let container = this.container;
        let clientX = this.clientX;
        let {
          scrollLeft,
          scrollWidth
        } = this.container;
        let {
          containerLeft,
          containerRight
        } = Ember.get(this, 'scrollBounds');
        let containerWidth = (0, _element.getOuterClientRect)(this.container).width * this.scale;
        let distanceToLeft = Math.max(clientX - containerLeft, 2);
        let distanceToRight = Math.max(containerRight - clientX, 2);
        let canScrollLeft = !stopAtLeft || scrollLeft !== 0;
        let canScrollRight = !stopAtRight || scrollLeft < scrollWidth - containerWidth;

        if (distanceToLeft <= SCROLL_THRESHOLD && canScrollLeft || distanceToRight <= SCROLL_THRESHOLD && canScrollRight) {
          let delta;

          if (distanceToLeft <= SCROLL_THRESHOLD) {
            delta = -SCROLL_THRESHOLD / distanceToLeft;
          } else {
            delta = SCROLL_THRESHOLD / distanceToRight;
          }

          delta = Math.round(delta);
          container.scrollLeft += delta;
          this.updateScroll(node, stopAtLeft, stopAtRight, callback);
          callback(node, delta);
        }
      }, this.token);
    }

  });

  _exports.default = _default;
});