define("ember-paper/templates/components/paper-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A57gvIk/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,10],null,[[\"isValid\",\"isInvalid\",\"isTouched\",\"isInvalidAndTouched\",\"input\",\"submit-button\",\"select\",\"autocomplete\",\"onSubmit\"],[[35,9],[35,8],[35,7],[35,6],[30,[36,2],[[35,5]],[[\"parentComponent\",\"onValidityChange\"],[[32,0],[30,[36,0],[[32,0],\"onValidityChange\"],null]]]],[30,[36,2],[[35,4]],[[\"type\"],[\"submit\"]]],[30,[36,2],[[35,3]],[[\"parentComponent\",\"onValidityChange\"],[[32,0],[30,[36,0],[[32,0],\"onValidityChange\"],null]]]],[30,[36,2],[[35,1]],[[\"parentComponent\",\"onValidityChange\"],[[32,0],[30,[36,0],[[32,0],\"onValidityChange\"],null]]]],[30,[36,0],[[32,0],\"onSubmit\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"autocompleteComponent\",\"component\",\"selectComponent\",\"submitButtonComponent\",\"inputComponent\",\"isInvalidAndTouched\",\"isTouched\",\"isInvalid\",\"isValid\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-form.hbs"
    }
  });

  _exports.default = _default;
});