define("ember-simple-auth-auth0/authenticators/auth0-lock-passwordless", ["exports", "ember-simple-auth-auth0/authenticators/auth0-base"], function (_exports, _auth0Base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _auth0Base.default.extend({
    auth0: Ember.inject.service(),

    authenticate(options) {
      return Ember.get(this, 'auth0').showPasswordlessLock(options);
    }

  });

  _exports.default = _default;
});