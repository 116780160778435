define('ember-highcharts/components/high-charts', ['exports', 'ember-highcharts/utils/option-loader', 'ember-highcharts/utils/chart-data', 'ember-highcharts/templates/components/high-charts', 'deepmerge'], function (exports, _optionLoader, _chartData, _highCharts, _deepmerge) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /* Map ember-highcharts modes to Highcharts methods
   * https://api.highcharts.com/class-reference/Highcharts.html
   */
  const CHART_TYPES = {
    StockChart: 'stockChart',
    Map: 'mapChart',
    undefined: 'chart'
  };

  exports.default = Ember.Component.extend({
    layout: _highCharts.default,
    classNames: ['highcharts-wrapper'],
    content: undefined,
    mode: undefined,
    chartOptions: undefined,
    chart: null,
    theme: undefined,
    callback: undefined,

    buildOptions: Ember.computed('chartOptions', 'content.[]', function () {
      let theme = Ember.getWithDefault(this, 'theme', {});
      let passedChartOptions = Ember.getWithDefault(this, 'chartOptions', {});

      let chartOptions = (0, _deepmerge.default)(theme, passedChartOptions);
      let chartContent = Ember.get(this, 'content');

      // if 'no-data-to-display' module has been imported, keep empty series and leave it to highcharts to show no data label.
      if (!Ember.get(this, 'content.length') && !Highcharts.Chart.prototype.showNoData) {
        chartContent = [{
          id: 'noData',
          data: 0,
          color: '#aaaaaa'
        }];
      }

      let defaults = { series: chartContent };

      return Ember.assign(defaults, chartOptions);
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      let { content, chart, mode } = Ember.getProperties(this, 'content', 'chart', 'mode');

      if (!content || !chart) {
        return;
      }

      let isStockChart = mode === 'StockChart';

      // create maps to make series data easier to work with
      let contentSeriesMap = (0, _chartData.getSeriesMap)(content);
      let chartSeriesMap = (0, _chartData.getSeriesMap)(chart.series);

      // remove and update current series
      let chartSeriesToRemove = [];

      chart.series.forEach(series => {
        if (isStockChart && series.name.match(/^Navigator/)) {
          return;
        }

        let contentSeries = contentSeriesMap[series.name];

        if (!contentSeries) {
          return chartSeriesToRemove.push(series);
        }

        let updatedKeys = (0, _chartData.getSeriesChanges)(contentSeries, series);

        // call series.update() when other series attributes like pointStart have changed
        if (updatedKeys.length) {
          series.update(contentSeries, false);
        } else {
          series.setData(contentSeries.data, false);
        }
      });

      chartSeriesToRemove.forEach(series => series.remove(false));

      // add new series
      content.forEach(contentSeries => {
        if (!chartSeriesMap.hasOwnProperty(contentSeries.name)) {
          chart.addSeries(contentSeries, false);
        }
      });

      // reset navigator data
      if (isStockChart && chart.xAxis.length) {
        chart.xAxis[0].setExtremes();
      }

      return chart.redraw();
    },

    drawAfterRender() {
      Ember.run.scheduleOnce('afterRender', this, 'draw');
    },

    draw() {
      let element = this.element && this.element.querySelector('.chart-container');
      let modeAttr = Ember.get(this, 'mode') || undefined;
      let mode = CHART_TYPES[modeAttr];
      let completeChartOptions = [Ember.get(this, 'buildOptions'), Ember.get(this, 'callback')];

      if (element) {
        let chart = Highcharts[mode](element, ...completeChartOptions);
        Ember.set(this, 'chart', chart);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this.drawAfterRender();
      (0, _optionLoader.setDefaultHighChartOptions)(Ember.getOwner(this));
    },

    willDestroyElement() {
      this._super(...arguments);

      if (Ember.get(this, 'chart')) {
        Ember.get(this, 'chart').destroy();
      }
    }
  });
});