define("ember-simple-auth-auth0/authorizers/jwt", ["exports", "ember-simple-auth/authorizers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    authorize(sessionData, block) {
      (false && !(false) && Ember.deprecate(`Ember Simple Auth Auth0: Authorizers are deprecated in Ember Simple Auth. Consider using the 'authorize' method of the auth0 service instead.`, false, {
        id: 'ember-simple-auth-auth0.jwtAuthorizer',
        until: '5.0.0',
        url: 'https://github.com/simplabs/ember-simple-auth#deprecation-of-authorizers'
      }));
      let userToken = sessionData['idToken'];

      if (Ember.isPresent(userToken)) {
        block('Authorization', `Bearer ${userToken}`);
      } else {
        Ember.debug('Could not find the authorization token in the session data for the jwt authorizer.');
      }
    }

  });

  _exports.default = _default;
});