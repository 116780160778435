define('ember-google-charts/utils/visualization-names', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    area: 'AreaChart',
    bar: 'BarChart',
    bubble: 'BubbleChart',
    calendar: 'Calendar',
    candlestick: 'CandlestickChart',
    column: 'ColumnChart',
    combo: 'ComboChart',
    gantt: 'Gantt',
    gauge: 'Gauge',
    geo: 'GeoChart',
    histogram: 'Histogram',
    line: 'LineChart',
    orgchart: 'OrgChart',
    pie: 'PieChart',
    sankey: 'Sankey',
    scatter: 'ScatterChart',
    steppedArea: 'SteppedAreaChart',
    table: 'Table',
    timeline: 'Timeline',
    treemap: 'Treemap'
  };
});