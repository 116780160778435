define("ember-paper/components/paper-autocomplete/ebd-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zDBhPZOm",
    "block": "{\"symbols\":[\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@preventScroll\",\"@rootEventType\",\"@vPosition\",\"@destination\",\"@top\",\"@left\",\"@right\",\"@width\",\"@height\",\"&attrs\",\"&default\"],\"statements\":[[8,\"basic-dropdown-content\",[[24,0,\"md-virtual-repeat-container md-autocomplete-suggestions-container\"],[17,13]],[[\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@preventScroll\",\"@rootEventType\",\"@vPosition\",\"@destination\",\"@top\",\"@left\",\"@right\",\"@width\",\"@height\",\"@otherStyles\",\"@animationEnabled\",\"@htmlTag\",\"@shouldReposition\"],[[32,1],[32,2],[32,3],[32,4],[32,5],[32,6],[32,7],[32,8],[32,9],[32,10],[32,11],[32,12],[32,0,[\"customStyles\"]],false,\"md-virtual-repeat-container\",[32,0,[\"shouldReposition\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[18,14,null],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-paper/components/paper-autocomplete/ebd-content/template.hbs"
    }
  });

  _exports.default = _default;
});