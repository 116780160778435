define("ember-composability-tools/templates/render-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/vOeB+hX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[35,1]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[18,1,[[35,0]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"yieldHash\",\"destinationElement\",\"-clear-element\",\"in-element\",\"shouldRender\",\"if\"]}",
    "meta": {
      "moduleName": "ember-composability-tools/templates/render-block.hbs"
    }
  });

  _exports.default = _default;
});