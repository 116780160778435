define("ember-paper/templates/components/paper-toaster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vsn+5C13",
    "block": "{\"symbols\":[\"toast\",\"&default\"],\"statements\":[[18,2,null],[2,\"\\n\"],[6,[37,4],[[35,0,[\"show\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,8],null,[[\"onClose\",\"position\",\"duration\",\"class\",\"parent\"],[[30,[36,7],[[32,0],[35,6],[35,0]],null],[35,0,[\"position\"]],[35,0,[\"duration\"]],[35,0,[\"toastClass\"]],[35,5]]],[[\"default\"],[{\"statements\":[[6,[37,4],[[35,0,[\"componentName\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,3],[[35,0,[\"componentName\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[6,[37,3],[[30,[36,2],[[32,1,[\"text\"]],\"expected `toast.text` to be a contextual component but found a string. Did you mean `(component toast.text)`? ('ember-paper/templates/components/paper-toaster.hbs' @ L12:C9) \"],null]],null,[[\"default\"],[{\"statements\":[[1,[35,0,[\"text\"]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[35,0,[\"action\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"primary\",\"accent\",\"warn\",\"onClick\"],[[35,0,[\"action\",\"primary\"]],[35,0,[\"action\",\"accent\"]],[35,0,[\"action\",\"warn\"]],[35,0,[\"action\",\"onClick\"]]]],[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[35,0,[\"action\",\"label\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"activeToast\",\"paper-button\",\"-assert-implicit-component-helper-argument\",\"component\",\"if\",\"parent\",\"onClose\",\"action\",\"paper-toast\"]}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-toaster.hbs"
    }
  });

  _exports.default = _default;
});