define("ember-paper/components/paper-autocomplete/reset-button/component", ["exports", "ember-css-transitions/mixins/transition-mixin", "@ember-decorators/component"], function (_exports, _transitionMixin, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PaperAutocompleteResetButton = (_dec = (0, _component.tagName)('button'), _dec(_class = (_class2 = class PaperAutocompleteResetButton extends Ember.Component.extend(_transitionMixin.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "type", _descriptor, this);

      _initializerDefineProperty(this, "tabindex", _descriptor2, this);

      _defineProperty(this, "transitionClass", 'ng');

      _defineProperty(this, "onReset", null);
    }

    click(e) {
      let onReset = this.get('onReset');

      if (onReset === null) {
        return;
      }

      onReset(e);
    }

    didTransitionOut() {
      super.didTransitionOut(...arguments);

      if (this.onDidTransitionOut) {
        this.onDidTransitionOut();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "type", [_component.attribute], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'button';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "tabindex", [_component.attribute], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '-1';
    }
  })), _class2)) || _class);
  var _default = PaperAutocompleteResetButton;
  _exports.default = _default;
});