define("ember-composable-helpers/helpers/optional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.optional = optional;

  function optional([action]) {
    if (typeof action === 'function') {
      return action;
    }

    return i => i;
  }

  var _default = Ember.Helper.helper(optional);

  _exports.default = _default;
});