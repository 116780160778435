define('ember-google-charts/utils/render-classic-chart', ['exports', 'ember-google-charts/utils/render-chart'], function (exports, _renderChart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = renderClassicChart;
  function renderClassicChart(data, options) {
    (false && Ember.warn(`renderClassicChart() has been deprecated. Use renderChart() instead. See https://github.com/sir-dunxalot/ember-google-charts#custom-charts`, {
      id: 'ember-google-charts.unified-render-util'
    }));


    const {
      design,
      element,
      type
    } = this.getProperties('design', 'element', 'type');

    return (0, _renderChart.default)(element, {
      data,
      design,
      options,
      type
    });
  }
});