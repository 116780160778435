define("ember-power-select/components/power-select/before-options", ["exports", "@ember-decorators/component", "ember-power-select/templates/components/power-select/before-options"], function (_exports, _component, _beforeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let BeforeOptions = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_beforeOptions.default), _dec3 = Ember._action, _dec4 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class BeforeOptions extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "autofocus", true);
    }

    willDestroyElement() {
      this._super(...arguments);

      if (this.searchEnabled) {
        Ember.run.scheduleOnce('actions', this, this.select.actions.search, '');
      }
    }

    handleKeydown(e) {
      if (this.onKeydown(e) === false) {
        return false;
      }

      if (e.keyCode === 13) {
        this.select.actions.close(e);
      }
    }

    focusInput(el) {
      Ember.run.later(() => {
        if (this.autofocus) {
          el.focus();
        }
      }, 0);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "handleKeydown", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeydown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "focusInput", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "focusInput"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = BeforeOptions;
});