define("ember-paper/templates/components/paper-progress-circular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a59SITnq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[15,\"viewBox\",[31,[\"0 0 \",[34,0],\" \",[34,0]]]],[15,5,[34,1]],[12],[2,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"none\"],[15,5,[34,2]],[15,\"stroke-dasharray\",[34,3]],[15,\"d\",[34,4]],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"diameter\",\"svgStyle\",\"pathStyle\",\"strokeDasharray\",\"d\"]}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-progress-circular.hbs"
    }
  });

  _exports.default = _default;
});