define("ember-paper/components/paper-toast", ["exports", "ember-paper/templates/components/paper-toast", "ember-invoke-action"], function (_exports, _paperToast, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperToast
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _paperToast.default,
    tagName: '',
    escapeToClose: false,
    swipeToClose: true,
    capsule: false,
    duration: 3000,
    position: 'bottom left',
    left: Ember.computed('position', function () {
      let [, x] = this.get('position').split(' ');
      return x === 'left';
    }),
    top: Ember.computed('position', function () {
      let [y] = this.get('position').split(' ');
      return y === 'top';
    }),
    // Calculate a default that is always valid for the parent of the backdrop.
    wormholeSelector: '#paper-toast-fab-wormhole',
    defaultedParent: Ember.computed.or('parent', 'wormholeSelector'),
    // Calculate the id of the wormhole destination, setting it if need be. The
    // id is that of the 'parent', if provided, or 'paper-wormhole' if not.
    destinationId: Ember.computed('defaultedParent', function () {
      let config = Ember.getOwner(this).resolveRegistration('config:environment');

      if (config.environment === 'test' && !this.get('parent')) {
        return '#ember-testing';
      }

      let parent = this.get('defaultedParent');
      let parentEle = typeof parent === 'string' ? document.querySelector(parent) : parent; // If the parent isn't found, assume that it is an id, but that the DOM doesn't
      // exist yet. This only happens during integration tests or if entire application
      // route is a dialog.

      if (typeof parent === 'string' && parent.charAt(0) === '#') {
        return `#${parent.substring(1)}`;
      } else {
        let {
          id
        } = parentEle;

        if (!id) {
          id = `${this.uniqueId}-parent`;
          parentEle.id = id;
        }

        return `#${id}`;
      }
    }),
    // Find the element referenced by destinationId
    destinationEl: Ember.computed('destinationId', function () {
      return document.querySelector(this.get('destinationId'));
    }),
    constants: Ember.inject.service(),

    _destroyMessage() {
      if (!this.isDestroyed) {
        (0, _emberInvokeAction.invokeAction)(this, 'onClose');
      }
    },

    init() {
      this._super(...arguments);

      this.uniqueId = Ember.guidFor(this);
    },

    willInsertElement() {
      this._super(...arguments);

      document.querySelector(this.get('destinationId')).classList.add('md-toast-animating');
    },

    didInsertElement() {
      this._super(...arguments);

      if (this.get('duration') !== false) {
        Ember.run.later(this, '_destroyMessage', this.get('duration'));
      }

      if (this.get('escapeToClose')) {
        // Adding Listener to body tag, FIXME
        this._escapeToClose = Ember.run.bind(this, e => {
          if (e.keyCode === this.get('constants.KEYCODE.ESCAPE') && this.get('onClose')) {
            this._destroyMessage();
          }
        });
        document.body.addEventListener('keydown', this._escapeToClose);
      }

      let y = this.get('top') ? 'top' : 'bottom';
      document.querySelector(this.get('destinationId')).classList.add(`md-toast-open-${y}`);
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.get('escapeToClose')) {
        document.body.removeEventListener('keydown', this._escapeToClose);
        this._escapeToClose = null;
      }

      let y = this.get('top') ? 'top' : 'bottom';
      document.querySelector(this.get('destinationId')).classList.remove(`md-toast-open-${y}`, 'md-toast-animating');
    },

    swipeAction() {
      if (this.get('swipeToClose')) {
        (0, _emberInvokeAction.invokeAction)(this, 'onClose');
      }
    }

  });

  _exports.default = _default;
});