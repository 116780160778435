define("ember-paper/components/paper-radio-proxiable", ["exports", "ember-paper/components/paper-radio-base", "ember-paper/mixins/proxiable-mixin"], function (_exports, _paperRadioBase, _proxiableMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module ember-paper
   */

  /**
   * @class PaperRadio
   * @extends PaperRadioBaseComponent
   * @uses ProxiableMixin
   */
  var _default = _paperRadioBase.default.extend(_proxiableMixin.default, {
    processProxy() {
      this.click();
    }

  });

  _exports.default = _default;
});