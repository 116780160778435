define("ember-simple-auth-auth0/authenticators/auth0-silent-auth", ["exports", "ember-simple-auth-auth0/authenticators/auth0-url-hash"], function (_exports, _auth0UrlHash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _auth0UrlHash.default.extend({
    auth0: Ember.inject.service(),

    authenticate(options) {
      return this._performSilentAuth(options);
    }

  });

  _exports.default = _default;
});