define("ember-google-charts/utils/format-data", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = formatData;
  function formatData(data) {
    const { google: { visualization } } = window;
    const isDataTable = data instanceof visualization.DataTable;

    return isDataTable ? data : visualization.arrayToDataTable(data);
  }
});