define("ember-paper/components/paper-select/no-matches-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y4WnZuRo",
    "block": "{\"symbols\":[\"@noMatchesMessage\"],\"statements\":[[10,\"md-content\"],[14,0,\"ember-power-select-options\"],[14,\"role\",\"listbox\"],[12],[2,\"\\n  \"],[10,\"md-option\"],[14,0,\"ember-power-select-option ember-power-select-option--no-matches-message\"],[14,\"role\",\"option\"],[12],[2,\"\\n    \"],[1,[32,1]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-paper/components/paper-select/no-matches-message/template.hbs"
    }
  });

  _exports.default = _default;
});