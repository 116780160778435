define("ember-paper/templates/components/paper-speed-dial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "65fpfyV/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"trigger\",\"actions\"],[[30,[36,0],[\"paper-speed-dial-trigger\"],[[\"speedDial\"],[[32,0]]]],[30,[36,0],[\"paper-speed-dial-actions\"],[[\"speedDial\"],[[32,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-speed-dial.hbs"
    }
  });

  _exports.default = _default;
});