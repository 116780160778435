define('ember-google-charts/utils/get-visualization-name', ['exports', 'ember-google-charts/utils/visualization-names'], function (exports, _visualizationNames) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getVisualizationName;
  function getVisualizationName(type) {
    return _visualizationNames.default[type];
  }
});