define("ember-paper/components/paper-autocomplete/eps-trigger/component", ["exports", "ember-paper/components/paper-autocomplete/eps-trigger/template", "@ember-decorators/component", "ember-paper/utils/unwrap-proxy"], function (_exports, _template, _component, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PaperSelectEpsTrigger = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember.computed('select.{searchText,selected}'), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember.computed('allowClear', 'select.disabled', 'resetButtonDestroyed'), _dec9 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class PaperSelectEpsTrigger extends Ember.Component {
    get text() {
      let selected = (0, _unwrapProxy.default)(this.select.selected);

      if (selected) {
        return this.getSelectedAsText();
      }

      return this.select.searchText;
    }

    getSelectedAsText() {
      let labelPath = this.extra.labelPath;

      if (labelPath) {
        return Ember.get(this.select.selected, labelPath);
      } else {
        return this.select.selected;
      }
    }

    handleKeydown(e) {
      let isLetter = e.keyCode >= 48 && e.keyCode <= 90 || e.keyCode === 32; // Keys 0-9, a-z or SPACE

      let isSpecialKeyWhileClosed = !isLetter && !this.select.isOpen && [13, 27, 38, 40].indexOf(e.keyCode) > -1;

      if (isLetter || isSpecialKeyWhileClosed) {
        e.stopPropagation();
      }
    }

    _onInput(value) {
      this.onInput({
        target: {
          value
        }
      });
    }

    didInsert(element) {
      this.triggerElement = element;
    }

    clear(e) {
      e.stopPropagation();

      if (this.onClear) {
        this.onClear();
      } else {
        this.select.actions.select(null);
        this.onInput({
          target: {
            value: ''
          }
        });
      }

      this.onFocus(e);
      this.triggerElement.querySelector('input').focus();
    }

    get showingClearButton() {
      // make room for clear button:
      // - if we're enabled
      // - or if we're disabled but the button still wasn't destroyed
      return this.allowClear && (!this.select.disabled || this.select.disabled && !this.resetButtonDestroyed);
    }

    resetButtonDestroyed() {
      if (this.disabled) {
        this.set('resetButtonDestroyed', true);
      }
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "text", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "text"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeydown", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeydown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_onInput", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "_onInput"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "didInsert", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "didInsert"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clear", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "clear"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showingClearButton", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "showingClearButton"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "resetButtonDestroyed", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "resetButtonDestroyed"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = PaperSelectEpsTrigger;
  _exports.default = _default;
});