define("ember-simple-auth-auth0/services/auth0", ["exports", "auth0-js", "auth0-lock", "ember-simple-auth-auth0/utils/create-session-data-object", "ember-simple-auth-auth0/utils/errors"], function (_exports, _auth0Js, _auth0Lock, _createSessionDataObject, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    inTesting: Ember.computed(function () {
      let config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config.environment === 'test';
    }),

    /**
     * The env config found in the environment config.
     * ENV['ember-simple-auth'].auth0
     *
     * @type {Object}
     */
    config: Ember.computed({
      get() {
        const emberSimpleAuthConfig = Ember.get(this, '_environmentConfig')['ember-simple-auth'];
        (false && !(emberSimpleAuthConfig) && Ember.assert('ember-simple-auth config must be defined', emberSimpleAuthConfig));
        (false && !(emberSimpleAuthConfig.auth0) && Ember.assert('ember-simple-auth.auth0 config must be defined', emberSimpleAuthConfig.auth0));
        return emberSimpleAuthConfig.auth0;
      }

    }),

    /**
     * The Auth0 App ClientID found in your Auth0 dashboard
     * @type {String}
     */
    clientID: Ember.computed.readOnly('config.clientID'),

    /**
     * The Auth0 App Domain found in your Auth0 dashboard
     * @type {String}
     */
    domain: Ember.computed.readOnly('config.domain'),

    /**
     * The URL to return to when logging out
     * @type {String}
     */
    logoutReturnToURL: Ember.computed('config.{logoutReturnToURL,logoutReturnToPath}', function () {
      const logoutReturnToPath = Ember.get(this, 'config.logoutReturnToPath');

      if (logoutReturnToPath) {
        (false && !(logoutReturnToPath.startsWith('/')) && Ember.assert('ember-simple-auth-auth0 logoutReturnToPath must start with /', logoutReturnToPath.startsWith('/')));
        return window.location.origin + logoutReturnToPath;
      }

      return Ember.get(this, 'config.logoutReturnToURL');
    }),

    /**
     * Enable user impersonation. This is opt-in due to security risks.
     * @type {bool}
     */
    enableImpersonation: Ember.computed.bool('config.enableImpersonation'),

    /**
     * Number of seconds between auto-renewing token via silent authentication.
     * @type {number}
     */
    silentAuthRenewSeconds: Ember.computed.readOnly('config.silentAuth.renewSeconds'),

    /**
     * Automatically perform silent authentication on session restore.
     * @type {bool}
     */
    silentAuthOnSessionRestore: Ember.computed.bool('config.silentAuth.onSessionRestore'),

    /**
     * Automatically perform silent authentication on session expiration.
     * @type {bool}
     */
    silentAuthOnSessionExpire: Ember.computed.bool('config.silentAuth.onSessionExpire'),

    /**
     * Default options to use when performing silent authentication.
     * This is a function rather than a computed property since the
     * default redirectUri needs to be regenerated every time.
     * @return {Object}
     */
    getSilentAuthOptions() {
      const defaultOptions = {
        responseType: 'token',
        scope: 'openid',
        redirectUri: window.location.origin,
        timeout: 5000
      };
      const configOptions = Ember.getWithDefault(this, 'config.silentAuth.options', {});
      const redirectPath = configOptions.redirectPath; // Support redirectPath which becomes redirectUri with the origin location prepended.

      if (redirectPath) {
        (false && !(redirectPath.startsWith('/')) && Ember.assert('ember-simple-auth-auth0 redirectPath must start with /', redirectPath.startsWith('/')));
        configOptions.redirectUri = window.location.origin + redirectPath;
      } // [XA] convoluted assign logic, just in case the Ember.Merge fallback is used.


      const options = {};
      Ember.assign(options, defaultOptions);
      Ember.assign(options, configOptions);
      return options;
    },

    /**
     * Perform Silent Authentication with Auth0's checkSession() method.
     * Returns the authenticated data if successful, or rejects if not.
     *
     * This method does NOT actually create an ember-simple-auth session;
     * use the authenticator rather than calling this directly.
     *
     * @method silentAuth
     */
    silentAuth(options) {
      if (!options) {
        options = this.getSilentAuthOptions();
      }

      return new Ember.RSVP.Promise((resolve, reject) => {
        const auth0 = this.getAuth0Instance();
        auth0.checkSession(options, (err, data) => {
          if (!err) {
            // special check: running this with Ember Inspector active
            // results in an ember version object getting returned for
            // some oddball reason. Reject and warn the user (dev?).
            if (data && Ember.get(data, 'type') === 'emberVersion') {
              reject(new _errors.Auth0Error('Silent Authentication is not supported when Ember Inspector is enabled. Please disable the extension to re-enable support.'));
            } else {
              resolve(data);
            }
          } else {
            reject(new _errors.Auth0Error(err));
          }
        });
      });
    },

    /**
     * Creates an authorization header from the session's token and calls
     * the given function, passing the header name & value as parameters.
     *
     * This method exists mainly for convencience, though it serves as a
     * handy drop-in replacement for the now-deprecated jwtAuthorizer.
     *
     * Just like with ember-simple-auth's authorizers, this method will do
     * nothing if the session is not authenticated.
     *
     * @method authorize
     */
    authorize(block) {
      if (Ember.get(this, 'session.isAuthenticated')) {
        const userToken = Ember.get(this, 'session.data.authenticated.idToken');

        if (Ember.isPresent(userToken)) {
          block('Authorization', `Bearer ${userToken}`);
        } else {
          Ember.debug('Could not find idToken in authenticated session data.');
        }
      }
    },

    showLock(options, clientID = null, domain = null, passwordless = false) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const lock = this.getAuth0LockInstance(options, clientID, domain, passwordless);

        this._setupLock(lock, resolve, reject);

        lock.show();
      });
    },

    showPasswordlessLock(options, clientID = null, domain = null) {
      return this.showLock(options, clientID, domain, true);
    },

    _setupLock(lock, resolve, reject) {
      lock.on('authenticated', authenticatedData => {
        if (Ember.isEmpty(authenticatedData)) {
          return reject(new _errors.Auth0Error('The authenticated data did not come back from the request'));
        }

        lock.getUserInfo(authenticatedData.accessToken, (error, profile) => {
          if (error) {
            return reject(new _errors.Auth0Error(error));
          }

          resolve((0, _createSessionDataObject.default)(profile, authenticatedData));
        });
      });
    },

    getAuth0LockInstance(options, clientID = null, domain = null, passwordless = false) {
      clientID = clientID || Ember.get(this, 'clientID');
      domain = domain || Ember.get(this, 'domain');
      const Auth0LockConstructor = Ember.get(this, passwordless ? '_auth0LockPasswordless' : '_auth0Lock');
      return new Auth0LockConstructor(clientID, domain, options);
    },

    getAuth0Instance(clientID = null, domain = null) {
      clientID = clientID || Ember.get(this, 'clientID');
      domain = domain || Ember.get(this, 'domain');
      const Auth0Constructor = Ember.get(this, '_auth0.WebAuth');
      return new Auth0Constructor({
        domain,
        clientID
      });
    },

    getAuth0LockPasswordlessInstance(options, clientID = null, domain = null) {
      return this.getAuth0LockInstance(options, clientID, domain, true);
    },

    navigateToLogoutURL(logoutUrl) {
      let {
        domain,
        logoutReturnToURL,
        clientID
      } = Ember.getProperties(this, 'domain', 'logoutReturnToURL', 'clientID');
      logoutReturnToURL = logoutUrl || logoutReturnToURL;

      if (!this.get('inTesting')) {
        window.location.replace(`https://${domain}/v2/logout?returnTo=${logoutReturnToURL}&client_id=${clientID}`);
      }
    },

    logout(logoutUrl) {
      Ember.get(this, 'session').invalidate().then(() => {
        this.navigateToLogoutURL(logoutUrl);
      });
    },

    _auth0: Ember.computed(function () {
      return _auth0Js.default;
    }),
    _auth0Lock: Ember.computed(function () {
      return _auth0Lock.Auth0Lock;
    }),
    _auth0LockPasswordless: Ember.computed(function () {
      return _auth0Lock.Auth0LockPasswordless;
    }),
    _environmentConfig: Ember.computed({
      get() {
        return Ember.getOwner(this).resolveRegistration('config:environment');
      }

    })
  });

  _exports.default = _default;
});