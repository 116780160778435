define("ember-apollo-client/services/apollo", ["exports", "fetch", "@apollo/client/core", "ember-apollo-client/index", "@ember/test-waiters"], function (_exports, _fetch, _core, _index, _testWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ApolloErrorWithResponse = void 0;
  _exports.getObservable = getObservable;
  _exports.unsubscribe = unsubscribe;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const apolloObservableWeakMap = new WeakMap();
  const apolloUnsubscribeWeakMap = new WeakMap();

  function getObservable(queryResult) {
    return apolloObservableWeakMap.get(queryResult);
  }

  function unsubscribe(queryResult) {
    let fn = apolloUnsubscribeWeakMap.get(queryResult);

    if (typeof fn === 'function') {
      return fn();
    }
  }

  class EmberApolloSubscription {
    constructor() {
      _defineProperty(this, "lastEvent", null);

      _defineProperty(this, "_apolloClientSubscription", null);
    }

    apolloUnsubscribe() {
      this._apolloClientSubscription.unsubscribe();
    }

    _onNewData(newData) {
      Ember.set(this, 'lastEvent', newData);
      Ember.sendEvent(this, 'event', [newData]);
    }

  }

  function extractNewData(resultKey, {
    data,
    loading
  }) {
    if (loading && Ember.isNone(data)) {
      // This happens when the cache has no data and the data is still loading
      // from the server. We don't want to resolve the promise with empty data
      // so we instead just bail out.
      //
      // See https://github.com/bgentry/ember-apollo-client/issues/45
      return null;
    }

    let keyedData = Ember.isNone(resultKey) ? data : data && Ember.get(data, resultKey);
    return keyedData || {};
  }

  function newDataFunc(observable, resultKey, resolve, unsubscribeFn = null) {
    let obj;
    return newData => {
      let dataToSend = extractNewData(resultKey, newData);

      if (dataToSend === null) {
        // see comment in extractNewData
        return;
      }

      if (Ember.isNone(obj)) {
        if (Ember.isArray(dataToSend)) {
          obj = Ember.A([...dataToSend]);
        } else {
          obj = { ...dataToSend
          };
        }

        if (!apolloObservableWeakMap.has(obj)) {
          apolloObservableWeakMap.set(obj, observable);
        }

        if (unsubscribeFn && !apolloUnsubscribeWeakMap.has(obj)) {
          apolloUnsubscribeWeakMap.set(obj, unsubscribeFn);
        }

        return resolve(obj);
      }

      Ember.run(() => {
        Ember.isArray(obj) ? obj.setObjects(dataToSend) : Ember.setProperties(obj, dataToSend);
      });
    };
  }

  class ApolloService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "client", null);
    }

    init() {
      super.init(...arguments);
      this.client = new _core.ApolloClient(this.clientOptions());
    }

    willDestroy() {
      // Ensure watch queries are not refeteched anymore
      // See: https://www.apollographql.com/docs/react/v2.6/api/apollo-client/#ApolloClient.clearStore
      // We guard against non-existance mostly to simplify tests a bit here
      if (typeof this.client.clearStore === 'function') {
        this.client.clearStore();
      }
    } // options are configured in your environment.js.


    get options() {
      // config:environment not injected into tests, so try to handle that gracefully.
      let config = Ember.getOwner(this).resolveRegistration('config:environment');

      if (config && config.apollo) {
        return config.apollo;
      } else if (Ember.testing) {
        return {
          apiURL: 'http://testserver.example/v1/graph'
        };
      }

      throw new Error('no Apollo service options defined');
    }

    cache() {
      return new _core.InMemoryCache();
    }

    link() {
      const {
        apiURL,
        requestCredentials
      } = this.options;
      const linkOptions = {
        uri: apiURL,
        fetch: _fetch.default
      };

      if (Ember.isPresent(requestCredentials)) {
        linkOptions.credentials = requestCredentials;
      }

      return (0, _core.createHttpLink)(linkOptions);
    }
    /**
     * This is the options hash that will be passed to the ApolloClient constructor.
     * You can override it if you wish to customize the ApolloClient.
     *
     * @method clientOptions
     * @return {!Object}
     * @public
     */


    clientOptions() {
      return {
        link: this.link(),
        cache: this.cache()
      };
    }
    /**
     * Executes a mutation on the Apollo client. The resolved object will
     * never be updated and does not have to be unsubscribed.
     *
     * @method mutate
     * @param {!Object} opts The query options used in the Apollo Client mutate.
     * @param {String} resultKey The key that will be returned from the resulting response data. If null or undefined, the entire response data will be returned.
     * @return {!Promise}
     * @public
     */


    mutate(opts, resultKey) {
      return (0, _testWaiters.waitForPromise)(new Ember.RSVP.Promise((resolve, reject) => {
        this.client.mutate(opts).then(result => {
          let dataToSend = Ember.isNone(resultKey) ? result.data : Ember.get(result.data, resultKey);
          return resolve(dataToSend);
        }).catch(error => {
          let errors;

          if (Ember.isPresent(error.networkError)) {
            error.networkError.code = 'network_error';
            errors = [error.networkError];
          } else if (Ember.isPresent(error.graphQLErrors)) {
            errors = error.graphQLErrors;
          }

          if (errors) {
            return reject({
              errors
            });
          }

          throw error;
        });
      }));
    }
    /**
     * Executes a `watchQuery` on the Apollo client. If updated data for this
     * query is loaded into the store by another query, the resolved object will
     * be updated with the new data.
     *
     * When using this method, it is important to call `apolloUnsubscribe()` on
     * the resolved data when the route or component is torn down. That tells
     * Apollo to stop trying to send updated data to a non-existent listener.
     *
     * @method watchQuery
     * @param {!Object} opts The query options used in the Apollo Client watchQuery.
     * @param {String} resultKey The key that will be returned from the resulting response data. If null or undefined, the entire response data will be returned.
     * @return {!Promise}
     * @public
     */


    watchQuery(opts, resultKey) {
      let observable = this.client.watchQuery(opts);
      let subscription;

      function unsubscribe() {
        subscription && subscription.unsubscribe();
      }

      return (0, _testWaiters.waitForPromise)(new Ember.RSVP.Promise((resolve, reject) => {
        // TODO: add an error function here for handling errors
        subscription = observable.subscribe({
          next: newDataFunc(observable, resultKey, resolve, unsubscribe),

          error(e) {
            reject(e);
          }

        });
      }));
    }
    /**
     * Executes a `subscribe` on the Apollo client. If this subscription receives
     * data, the resolved object will be updated with the new data.
     *
     * When using this method, it is important to call `apolloUnsubscribe()` on
     * the resolved data when the route or component is torn down. That tells
     * Apollo to stop trying to send updated data to a non-existent listener.
     *
     * @method subscribe
     * @param {!Object} opts The query options used in the Apollo Client subscribe.
     * @param {String} resultKey The key that will be returned from the resulting response data. If null or undefined, the entire response data will be returned.
     * @return {!Promise}
     * @public
     */


    subscribe(opts, resultKey = null) {
      const observable = this.client.subscribe(opts);
      const obj = new EmberApolloSubscription();
      return (0, _testWaiters.waitForPromise)(new Ember.RSVP.Promise((resolve, reject) => {
        let subscription = observable.subscribe({
          next: newData => {
            let dataToSend = extractNewData(resultKey, newData);

            if (dataToSend === null) {
              // see comment in extractNewData
              return;
            }

            Ember.run(() => obj._onNewData(dataToSend));
          },

          error(e) {
            reject(e);
          }

        });
        obj._apolloClientSubscription = subscription;
        resolve(obj);
      }));
    }
    /**
     * Executes a single `query` on the Apollo client. The resolved object will
     * never be updated and does not have to be unsubscribed.
     *
     * @method query
     * @param {!Object} opts The query options used in the Apollo Client query.
     * @param {String} resultKey The key that will be returned from the resulting response data. If null or undefined, the entire response data will be returned.
     * @return {!Promise}
     * @public
     */


    query(opts, resultKey) {
      return (0, _testWaiters.waitForPromise)(new Ember.RSVP.Promise((resolve, reject) => {
        this.client.query(opts).then(result => {
          let response = result.data;

          if (!Ember.isNone(resultKey) && response) {
            response = Ember.get(response, resultKey);
          }

          if (opts.errorPolicy === 'all' && result.errors && result.errors.length > 0) {
            return reject(new ApolloErrorWithResponse({
              response,
              errors: result.errors
            }));
          }

          return resolve(response);
        }).catch(error => {
          return reject(error);
        });
      }));
    }
    /**
     * Executes a `watchQuery` on the Apollo client and tracks the resulting
     * subscription on the provided query manager.
     *
     * @method managedWatchQuery
     * @param {!Object} manager A QueryManager that should track this active watchQuery.
     * @param {!Object} opts The query options used in the Apollo Client watchQuery.
     * @param {String} resultKey The key that will be returned from the resulting response data. If null or undefined, the entire response data will be returned.
     * @return {!Promise}
     * @private
     */


    managedWatchQuery(manager, opts, resultKey) {
      let observable = this.client.watchQuery(opts);
      let subscription;

      function unsubscribe() {
        subscription && subscription.unsubscribe();
      }

      return (0, _testWaiters.waitForPromise)(new Ember.RSVP.Promise((resolve, reject) => {
        subscription = observable.subscribe({
          next: newDataFunc(observable, resultKey, resolve, unsubscribe),

          error(e) {
            reject(e);
          }

        });
        manager.trackSubscription(subscription);
      }));
    }
    /**
     * Executes a `subscribe` on the Apollo client and tracks the resulting
     * subscription on the provided query manager.
     *
     * @method managedSubscribe
     * @param {!Object} manager A QueryManager that should track this active subscribe.
     * @param {!Object} opts The query options used in the Apollo Client subscribe.
     * @param {String} resultKey The key that will be returned from the resulting response data. If null or undefined, the entire response data will be returned.
     * @return {!Promise}
     * @private
     */


    managedSubscribe(manager, opts, resultKey = null) {
      return this.subscribe(opts, resultKey).then(obj => {
        manager.trackSubscription(obj._apolloClientSubscription);
        return obj;
      });
    }

    createQueryManager() {
      return new _index.QueryManager(this);
    }

  }

  _exports.default = ApolloService;

  class ApolloErrorWithResponse extends Error {
    constructor({
      response,
      errors
    }) {
      let message = 'The server responded with an error.';
      super(message);
      this.name = 'ApolloErrorWithResponse';
      this.response = response;
      this.errors = errors || [];
    }

  }

  _exports.ApolloErrorWithResponse = ApolloErrorWithResponse;
});