define("ember-paper/components/paper-menu/trigger/component", ["exports", "ember-paper/components/paper-menu/trigger/template", "@ember-decorators/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  let PaperMenuTrigger = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = class PaperMenuTrigger extends Ember.Component {}) || _class) || _class);
  var _default = PaperMenuTrigger;
  _exports.default = _default;
});