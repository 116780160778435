define("ember-paper/components/paper-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/aZrmEpi",
    "block": "{\"symbols\":[\"@padding\",\"@scroll-y\",\"&attrs\",\"&default\"],\"statements\":[[11,\"md-content\"],[16,0,[31,[\"md-default-theme \",[30,[36,0],[[32,1],\"md-padding\"],null]]]],[16,\"md-scroll-y\",[32,2]],[17,3],[12],[2,\"\\n  \"],[18,4,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ember-paper/components/paper-content/template.hbs"
    }
  });

  _exports.default = _default;
});