define("ember-paper/templates/components/paper-card-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5LkfPPPo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"md-media-\",[34,0]]]],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"img\"],[15,0,[31,[\"md-media-\",[34,0]]]],[15,\"src\",[34,1]],[15,\"alt\",[34,2]],[15,\"title\",[34,3]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"size\",\"src\",\"alt\",\"title\",\"if\"]}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-card-media.hbs"
    }
  });

  _exports.default = _default;
});