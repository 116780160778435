define("ember-paper/templates/components/paper-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bpoaMkGV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,18],[[35,17]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,14],null,[[\"locked-open\",\"opaque\",\"fixed\",\"class\",\"onClick\"],[[35,13],[35,12],[30,[36,11],[[35,10],true],null],\"md-dialog-backdrop\",[30,[36,9],[[32,0],\"outsideClicked\"],null]]]]],[2,\"\\n\"],[6,[37,16],null,[[\"class\",\"outsideClicked\"],[[30,[36,7],[[35,15]],null],[30,[36,9],[[32,0],\"outsideClicked\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,8],null,[[\"class\",\"origin\",\"defaultedParent\",\"defaultedOpenFrom\",\"defaultedCloseTo\",\"fullscreen\",\"focusOnOpen\"],[[30,[36,7],[[35,6]],null],[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"focusOnOpen\",\"fullscreen\",\"defaultedCloseTo\",\"defaultedOpenFrom\",\"defaultedParent\",\"origin\",\"class\",\"readonly\",\"paper-dialog-inner\",\"action\",\"parent\",\"unless\",\"opaque\",\"isLockedOpen\",\"paper-backdrop\",\"dialogContainerClass\",\"paper-dialog-container\",\"destinationEl\",\"in-element\"]}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-dialog.hbs"
    }
  });

  _exports.default = _default;
});