define("ember-math-helpers/helpers/sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sign = sign;

  /**
   * Executes `Math.sign` on the number passed to the helper.
   *
   * ```hbs
   * {{sign a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.sign`
   * @return {number} The sign of the passed number
   */
  function sign([number]) {
    return Math.sign(number);
  }

  var _default = Ember.Helper.helper(sign);

  _exports.default = _default;
});