define("ember-paper/templates/components/paper-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rG3fFk/v",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[6,[37,7],[[35,6]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"md-panel-outer-wrapper md-panel-is-showing\"],[15,5,[34,0]],[12],[2,\"\\n\"],[6,[37,5],null,[[\"class\",\"position\",\"anchorElement\",\"hide\"],[[35,4],[35,3],[35,2],[35,1]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"containerStyle\",\"hideTooltip\",\"anchorElement\",\"position\",\"class\",\"paper-tooltip-inner\",\"destinationEl\",\"in-element\",\"renderTooltip\",\"if\"]}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-tooltip.hbs"
    }
  });

  _exports.default = _default;
});